import React from 'react';

import TemplateDocsPSPage from '../../blocks/templateDocsPS';

import RectangleLink from "../../components/RectangleLink";
import Clarification from "../../components/Clarification";

import enMessages from '../../i18n/rules-documents-dengi/rules/en.json'
import ruMessages from '../../i18n/rules-documents-dengi/rules/ru.json'

import enMainMessages from '../../i18n/rules-documents-dengi/en.js'
import ruMainMessages from '../../i18n/rules-documents-dengi/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const RulesDocsPage = ({ pathContext: { locale } }) => {
    const section = 'rules';

    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
        if (locale === 'en'){
            window.location.replace('/en/404');
        }
    };

    return (
        <TemplateDocsPSPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >
            {messages[locale].content.map(item => {
                return <>
                    <RectangleLink
                        title={item.title}
                        label={item.label}
                        link={item.link}
                        target="_blank"
                    />
                    {item.clarification && (
                        <Clarification
                            label={item.clarification.label}
                            text={item.clarification.text}
                        />
                    )}
                    </>
            })}
        </TemplateDocsPSPage>
    )
};

export default RulesDocsPage;
